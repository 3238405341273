@import '../_styles/breakpoints';

.network {
  margin-top: 4rem;

  h1 {
    margin-bottom: 1.5rem;
  }

  &__all-members {
    margin-top: 2rem;
    margin-bottom: 6.75rem;

    display: flex;

    @include breakpoint(desktop) {
      flex-direction: column;
      align-items: center;
      row-gap: 1.375rem;
      column-gap: 1.375rem;
    }

    &__content {
      width: 100%;
      margin-left: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      row-gap: 1.375rem;
      column-gap: 1.375rem;

      &__create-groupchat {
        margin-top: 0;
        width: 100%;
      }

      > p {
        text-align: center;
        width: 100%;
        color: var(--grey);
      }

      @include breakpoint(tablet) {
        margin: 0;
      }

      > .network__all-members__meta-count {
        width: 100%;
      }
    }

    &__meta-count {
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.125rem;
      color: var(--grey-44);
    }

    &__load-more {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: center;

      .ecl-button {
        padding-left: 0.5rem;
      }
    }
  }
}
