.ecl-file__footer {
  position: relative;
}
// @import '../../_styles/text';
// @import '../../_styles/breakpoints';
//
// .document {
//   display: flex;
//   align-items: center;
//   background-color: var(--grey-light);
//   padding: 2rem;
//
//   > div {
//     display: flex;
//     width: 100%;
//     > svg {
//       color: var(--grey-dark);
//       opacity: 0.5;
//     }
//   }
//
//   &__content {
//     flex: 1;
//     margin: 0 1rem;
//
//     &__title,
//     &__file-info {
//       display: block;
//       color: var(--grey-dark);
//     }
//
//     &__title {
//       font-weight: bold;
//     }
//
//     &__file-info {
//       @include text(small);
//     }
//   }
//
//   @include breakpoint(phone) {
//     flex-direction: column;
//     align-items: flex-start;
//     gap: 1rem;
//   }
// }
