.notification-item {
  border-bottom: 1px solid var(--grey-e3);
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--blue-grey);
  min-height: 7rem;

  &.is-seen {
    background-color: var(--white);
  }

  &__wrapper {
    display: flex;
    align-items: center;

    .ecl-icon {
      fill: none;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 1rem;

      p {
        margin: 0.5rem 0;
        font-weight: bold;
        color: var(--blue);
      }

      span {
        color: var(--grey-44);
        font-size: 0.875rem;
      }
    }

    &__actions {
      display: flex;
      align-items: center;

      .ecl-button {
        &:nth-of-type(2) {
          margin: 0 1rem;
        }
      }
    }
  }

  &__indicator {
    width: 1.1rem;
    height: 1.1rem;
    background-color: var(--blue);
    border-radius: 50%;
  }
}
