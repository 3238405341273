.about {
  padding: 1.5rem 1rem;
  background-color: var(--grey-light);
  border: 1px solid var(--grey-brown);

  h3 {
    margin-bottom: 2rem;
  }

  h5 {
    font-size: 1.125rem;
    margin: 2rem 0 1rem;
  }

  &__topics {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
  }
}
