@import '../../../_styles/colors';
@import '../../../_styles/constants';

$padding: 2.5rem;

.dropzone {
  border: 2px dashed var(--shadow);
  padding: $padding;
  cursor: pointer;

  &:hover,
  &.active {
    color: var(--primary);
    border-color: var(--primary);
  }

  &.error {
    border-color: var(error-border-color);
    background-color: var(error-background-color);
  }

  &.animated-error {
    animation: alert 1s;
  }

  &.disabled,
  &.no-hover {
    cursor: default;
    &:hover {
      color: inherit;
      border-color: var(--shadow);
    }
  }

  &.disabled {
    opacity: $disabled-opacity;
    background-color: var(--disabled-background-color);
  }

  .trigger {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .icon {
      color: var(--primary);
    }

    .title {
      margin-top: 1rem;
    }

    .requirements {
      color: var(--grey);
      font-size: 1.4rem;
      margin-top: 0.5rem;
    }
  }

  .preview {
    position: relative;
    display: flex;
    align-items: center;
    margin-right: -$padding;
    color: var(--text-color);

    &:not(:first-child) {
      margin-top: $padding * 2;
    }

    &:not(:last-child):after {
      position: absolute;
      bottom: -$padding;
      content: '';
      display: block;
      width: calc(100% - #{$padding});
      height: 1px;
      background-color: var(--grey-light);
    }

    img {
      width: 25%;
    }

    .details {
      padding-left: 3rem;
      flex: 1;
      overflow: hidden;
      word-wrap: break-word;
      > * {
        display: block;
      }

      .name {
        max-height: 4.5rem;
        overflow: hidden;
      }

      .size {
        color: var(--grey);
      }
    }
  }
}

@keyframes alert {
  0% {
    border-color: var(--shadow);
  }
  10% {
    border-color: var(--error-border-color);
  }
  20% {
    border-color: var(--shadow);
  }
  30% {
    border-color: var(--error-border-color);
  }
  40% {
    border-color: var(--shadow);
  }
  50% {
    border-color: var(--error-border-color);
  }
  60% {
    border-color: var(--shadow);
  }
  70% {
    border-color: var(--error-border-color);
  }
  80% {
    border-color: var(--shadow);
  }
  90% {
    border-color: var(--error-border-color);
  }
  100% {
    border-color: var(--shadow);
  }
}
