@import '../../_styles/breakpoints';

.hub-detail {
  .back-navigation {
    margin: 1.5rem auto;
  }

  .hub__banner {
    margin-bottom: 4rem;
  }

  &__section {
    margin-bottom: 3rem;

    h3 {
      margin-bottom: 1.5rem;
    }

    &--grey {
      padding: 1.5rem 1rem;
      background-color: var(--grey-light);
      border: 1px solid var(--grey-brown);
    }

    &__empty {
      color: var(--grey);
    }
  }

  &__book-a-meeting {
    p:first-of-type {
      margin-bottom: 0.5rem;
    }

    button {
      margin-top: 2.5rem;
    }
  }

  &__navigation {
    margin-bottom: 1.75rem;
  }
}
