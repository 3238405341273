.favorite-button {
  background-color: var(--white);
  border-radius: 1.5rem;
  position: absolute;

  &:hover,
  &:focus {
    background-color: var(--mercury);
  }
}
