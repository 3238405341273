@import '../../../_styles/breakpoints';

.my-events {
  background-color: var(--blue-grey);

  .ecl-container {
    margin: 3rem auto;
  }

  .list__title {
    margin-bottom: 1.5rem;
  }

  &__empty {
    color: var(--grey-44);
  }

  &__wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;

    .ecl-card.ecl-content-item__card {
      background-color: transparent;
    }

    @include breakpoint(tablet) {
      grid-template-columns: 1fr;
    }
  }

  .event-item-link {
    box-shadow: none;
    border-bottom: 1px solid var(--grey);
  }

  section > button {
    margin-top: 1.5rem;
  }
}
