.hub__banner {
  background-color: var(--primary-dark);
  position: relative;
  height: 400px;

  > div {
    background-image: linear-gradient(to right, var(--primary-dark), var(--primary-dark) 60%, transparent);
    padding-bottom: 4rem;
    height: 100%;

    h2 {
      color: var(--white);
      padding: 4rem 0 3rem;
    }

    .hub__banner__announcement {
      position: relative;
      display: flex;
      background-color: var(--white);
      max-width: 46.25rem;
      height: 12.5rem;
      border: 2px solid var(--primary-dark);

      &__content {
        overflow-y: auto;
        padding: 0.75rem;

        > a {
          margin-top: 0.75rem;
          padding-left: 0;
        }
      }

      &__controls {
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        background-color: var(--white);

        > span {
          font-weight: bold;
          font-size: 1.125rem;
          margin: 0.5rem;
        }
      }
    }

    &:before {
      content: '';
      position: absolute;
      height: 100%;
      background-color: var(--primary-dark);
      width: 500px;
      left: -500px;
    }
  }

  img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 0;
  }
}
