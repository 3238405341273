.ecl-card__label {
  padding: 0.3rem 0.8rem;
  text-transform: uppercase;

  &.ecl-card__label__live {
    background-color: var(--green);
    position: relative;
    padding-left: 1.75rem;

    &:after {
      content: '';
      width: 10px;
      height: 10px;
      background-color: var(--red);
      border: 1px solid var(--white);
      position: absolute;
      border-radius: 50%;
      left: 0.75rem;
      top: 0.4rem;
    }
  }

  &.ecl-card__label__comingsoon {
    background-color: var(--green);
  }

  &.ecl-card__label__rewatch {
    background-color: var(--mercury);
    // border: 1px solid var(--black);

    &.ecl-card__label__dark {
      border: 1px solid var(--white);
      color: var(--white);
    }
  }

  &.ecl-card__label__featured {
    background-color: var(--primary);
    color: var(--white);
  }
}
