:root {
  --primary: #3860ED;
  --primary-dark: #254c92;
  --primary-light: #7fa1c9;
  --text-color: #000;
  --background-color: #002147;
  --disabled-background-color: #f3f3f3;
  --error-background-color: #fff6f6;
  --error-border-color: #e0b4b4;

  --white: #ffffff;
  --black: #000000;
  --red: #da2131;
  --green: #c1f5b4;
  --green-dark: #467a39;
  --grey-dark: #404040;
  --grey-44: #707070;
  --grey-e3: #e3e3e3;
  --grey: #9f9f9f;
  --grey-brown: #cfcfcf;
  --grey-black: #2c2a29;
  --silver: #c4c4c4;
  --night: #333333;
  --mercury: #ebebeb;
  --grey-light: #f5f5f5;
  --yellow: #ffd617;
  --blue: #3860ED;
  --blue-light: #bfd0e4;
  --blue-dark: #003776;
  --blue-dark-black: #002f67;
  --blue-grey: #f2f5f9;

  --twitter: #6090b5;
  --twitter-icon: #8ecaeb;
  --facebook: #426398;
  --facebook-icon: #5c79b6;
  --linkedin: #196ba0;
  --linkedin-icon: #3e83c2;
  --copy-icon: #70b5f4;

  --dark-blue-20: rgb(0, 47, 103, 0.2);
  --dark-blue-10: rgb(0, 47, 103, 0.1);

  --shadow: rgb(112, 112, 112, 0.5);
}
