@import '../../../_styles/breakpoints';

.post {
  position: relative;
  display: flex;
  background-color: var(--grey-light);
  margin-top: 1rem;

  @include breakpoint(tablet) {
    flex-direction: column;
  }

  .ecl-card__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__image {
    width: 20rem;
    height: 20rem;
    object-fit: cover;

    @include breakpoint(tablet) {
      width: 100%;
    }
  }

  &__content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin: 1rem 0;
  }

  .favorite-button {
    top: 0.5rem;
    left: 0.5rem;
  }
}
