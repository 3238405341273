@import '../_styles/text';
@import '../_styles/spacing';

.external-highlights {
  background-color: var(--blue-grey);

  &__content {
    @include padding;

    .external-highlight {
      padding: 2.5rem 0 0 0;

      &__image {
        width: 100%;
        max-height: 14.5rem;
        object-fit: cover;
      }

      &__title {
        @include heading(4);
        margin-top: 0.25rem;
        text-decoration: none;
        color: var(--blue);

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      }

      &__description {
        margin-top: 0.25rem;
      }
    }
  }
}
