@import '../../_styles/buttons';

.speaker {
  display: flex;
  align-items: center;
  margin: 1em 0;

  .image-container {
    width: 3rem;
    height: 3rem;
  }

  img {
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
    width: 3rem;
    height: 3rem;
  }

  .ecl-icon {
    fill: none;
  }

  &__content {
    margin-left: 0.5rem;

    button {
      display: block;
      @include unstyled-button;
      color: var(--blue);
      font-weight: bold;
    }

    span {
      color: var(--grey-dark);
    }
  }
}
