.back-to-top {
  position: fixed;
  font-weight: 900;
  bottom: 2rem;
  right: 3.5rem;
  z-index: 60;
  cursor: pointer;
  color: var(--blue);
  display: flex;
  align-items: center;

  svg {
    margin-left: 0.5rem;
  }
}

.in-footer {
  color: var(--white);
}
