@import '../../../_styles/spacing';
@import '../../../_styles/breakpoints';

.research-innovation-platform {
  @include padding;

  &__content {
    margin-top: 2rem;
    display: flex;
    gap: 2rem;

    @include breakpoint(desktop) {
      flex-direction: column;
    }

    .ecl-button {
      margin-top: 2rem;

      .icon {
        margin-left: 1rem;
      }
    }
  }
}
