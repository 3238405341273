.player {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */
  border: 1px solid var(--grey-light);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  span {
    position: absolute;
    top: 40%;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
  }
}
