@import '../_styles/spacing';
@import '../_styles/breakpoints';
@import '../_styles/buttons';

.programme {
  @include padding;

  &__content {
    display: flex;
    padding-top: 2rem;

    @include breakpoint(desktop) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    &__sessions {
      width: 100%;
      margin-left: 1rem;

      @include breakpoint(tablet) {
        margin: 0;
      }

      &__slots {
        &__timeblock {
          display: flex;
          gap: 1rem;

          > span {
            font-weight: bold;
            border-right: 2px solid var(--black);
            padding-right: 1rem;
          }

          > div {
            flex: 1;
          }

          &__session {
            position: relative;
            margin-bottom: 2.2rem;

            &__bullet {
              width: 1rem;
              height: 1rem;
              border-radius: 50%;
              background-color: var(--white);
              border: 4px solid var(--yellow);
              position: absolute;
              top: 0;
              left: -1.6rem;
            }
          }
        }
      }
    }
  }
}
