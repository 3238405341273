@import './breakpoints';

.detail-content {
  display: grid;
  grid-template-columns: 66% 34%;

  main {
    padding-right: 1rem;
    border-right: 1px solid var(--grey-brown);
  }

  aside {
    padding-left: 1rem;
    padding-bottom: 2rem;
  }

  section {
    margin-bottom: 2rem;
  }

  @include breakpoint(tablet) {
    grid-template-columns: 1fr;

    main {
      padding-right: 0;
      border-right: none;
    }

    aside {
      grid-column-start: 1;
      padding-left: 0;
    }
  }
}
