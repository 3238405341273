.ecl-card {
  max-width: 348px;
  // height: 32.2rem;
  // height: auto;
  background-color: var(--white);
  position: relative;

  &__body {
    position: initial;
  }

  &__image {
    // width: 20rem;
    // height: 13.5rem;
    object-fit: cover;
  }

  &__label-container {
    display: flex;
    padding: 0.5rem 0 1rem 0rem;
  }

  &__info-container,
  &__tag-container {
    margin-top: 1rem;
  }

  &__info-item {
    // margin-top: 0.5rem;
    color: var(--grey-44);
    display: flex;
    align-items: center;

    span {
      margin-left: 0.75rem;
    }
  }

  &__tag-container {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    flex-wrap: wrap;
    // position: absolute;
    // bottom: 1rem;

    &__tag-item {
      display: flex;
      margin-right: 0;

      &:last-of-type {
        white-space: nowrap;
      }
    }
  }

  .ecl-button--xs {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
  }
}
