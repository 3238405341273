.attend-button {
  margin-top: 1.6rem;
  display: flex;
  gap: 1rem;

  .is-attending {
    background-color: #467a39;
    color: var(--white);

    .ecl-icon {
      path {
        fill: var(--white);
      }
    }
  }

  .is-not-attending {
    .ecl-icon {
      transform: rotate(90deg);
    }
  }
}
