@import '../../_styles/breakpoints';

.hub-detail__external-resources {
  div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 1rem;

    @include breakpoint(phone) {
      grid-template-columns: 1fr;
    }
  }
}
