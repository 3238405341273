@import './constants';
@import './breakpoints';

/* MIXINS */
@mixin text($type) {
  font-weight: $font-weight-normal;

  @if $type == lead {
    font-size: 1.375rem;

    @include breakpoint(phone) {
      font-size: 1.125rem;
    }
  } @else if $type == medium {
    font-size: 1rem;
  } @else if $type == small {
    font-size: 0.875rem;
  } @else if $type == extra-small {
    font-size: 0.75rem;
  }
}

@mixin heading($level) {
  font-weight: $font-weight-bold;
  color: var(--text-color);

  @if $level == 1 {
    font-size: 2rem;

    @include breakpoint(phone) {
      font-size: 1.75rem;
    }
  } @else if $level == 2 {
    font-size: 1.75rem;

    @include breakpoint(phone) {
      font-size: 1.5rem;
    }
  } @else if $level == 3 {
    font-size: 1.5rem;

    @include breakpoint(phone) {
      font-size: 1.25rem;
    }
  } @else if $level == 4 {
    font-size: 1.25rem;

    @include breakpoint(phone) {
      font-size: 1rem;
    }
  }
}

@mixin readMore($clamp) {
  display: -webkit-box;
  -webkit-line-clamp: $clamp;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* USAGE  */

h1 {
  @include heading(1);
}

h2 {
  @include heading(2);
}

h3 {
  @include heading(3);
}

h4 {
  @include heading(4);
}

.text {
  &__lead {
    @include text(lead);
  }

  &__medium {
    @include text(medium);
  }

  &__small {
    @include text(small);
  }

  &__extra-small {
    @include text(extra-small);
  }
}
