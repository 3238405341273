/* Keep in sync useResponsive */
$xlmonitor-breakpoint: 1920px;
$lgmonitor-breakpoint: 1680px;
$monitor-breakpoint: 1140px;
$desktop-breakpoint: 996px;
$tablet-breakpoint: 768px;
$phone-breakpoint: 480px;

/*
Huge-monitor first instead off mobile first
Why? Because we only have desktop designs and we have made mobile designs ourselves
*/
@mixin breakpoint($point) {
  @if $point == phone {
    @media (max-width: $phone-breakpoint) {
      @content;
    }
  } @else if $point == tablet {
    @media (max-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $point == desktop {
    @media (max-width: $desktop-breakpoint) {
      @content;
    }
  } @else if $point == monitor {
    @media (max-width: $monitor-breakpoint) {
      @content;
    }
  } @else if $point == lgmonitor {
    @media (max-width: $lgmonitor-breakpoint) {
      @content;
    }
  } @else if $point == xlmonitor {
    @media (max-width: $xlmonitor-breakpoint) {
      @content;
    }
  }
}
