@import '../../_styles/breakpoints';

.user-modal {
  position: relative;
  min-width: $tablet-breakpoint;
  max-width: $desktop-breakpoint;
  padding: 0 3rem;
  background-color: var(--white);
  max-height: 90vh;
  overflow-y: auto;

  @include breakpoint(tablet) {
    min-width: 100%;
    min-height: 100vh;
  }

  > .ecl-button {
    position: absolute;
    right: 0.75rem;
    top: 0.75rem;
  }

  .user-detail {
    &__information {
      &__content {
        display: flex;
        align-items: flex-start;

        .ecl-icon {
          fill: none;
        }

        img {
          object-fit: cover;
          object-position: top;
          flex-shrink: 0;
        }

        .user-info-line--buttons {
          display: flex;
          gap: 1rem;
          margin-top: 1rem;

          .ecl-icon > * {
            fill: white;
          }
        }
      }
    }

    &__events__grid {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }

    &__posts {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }
}
