@import '../_styles/spacing';
@import '../_styles/breakpoints';

.speakers {
  @include padding;

  &__content {
    display: flex;
    padding-top: 2rem;

    @include breakpoint(desktop) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    &__container {
      .create-groupchat {
        margin: 1rem;
        margin-top: 0;
      }

      &__grid {
        width: 100%;
        margin-left: 1rem;
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;

        @include breakpoint(tablet) {
          grid-template-columns: repeat(2, 1fr);
          margin: 0;
        }

        &__create-groupchat {
          grid-column: 1 / span 3;
        }
      }
    }
  }
}
