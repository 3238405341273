.modal {
  background-color: var(--white);
  max-width: 47.5rem;
  width: 100%;
  max-height: 90vh;
  overflow-y: scroll;

  &__header {
    background-color: var(--blue);
    padding: 2.3rem 3rem;
    position: sticky;
    top: 0;

    > .ecl-button {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
      z-index: 1;
      color: var(--white);
    }

    h2 {
      color: var(--white);
    }
  }

  &__content {
    padding: 3rem;
  }
}
