.ecl-site-header-core__login-description {
  border: none;
  padding: 0;
  text-decoration: none;
  font-size: 0.875rem;

  > span {
    color: var(--black);
  }
}

.ecl-site-header-core__login-container {
  .my-profile,
  .logout {
    padding: 0;
    font-weight: normal;
  }
}
