@import '../../../_styles/text';

.detail-modal {
  &__content {
    color: var(--grey-dark);

    .icon {
      margin-right: 0.5rem;
    }

    .speaker {
      margin-top: 1rem;
    }

    & > *:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
