@import '../../_styles/breakpoints';

.community-posts {
  & > section {
    padding: 3rem 0;
  }

  &__featured {
    background-color: var(--blue-grey);

    .title {
      margin-bottom: 3rem;
    }
  }

  &__content {
    display: flex;
    padding-top: 2rem;

    @include breakpoint(desktop) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    &__posts {
      width: 100%;
      margin-left: 1rem;

      @include breakpoint(tablet) {
        margin: 0;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__label {
          color: var(--grey-44);
        }

        &__sort {
          display: none;

          &-open {
            background-color: var(--grey-light);
            box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.16);
            border: 1px solid rgba(0, 0, 0, 0.15);
            display: flex;
            flex-direction: column;
            position: absolute;
            z-index: 99;
          }
        }
      }

      &__empty {
        color: var(--grey-44);
        text-align: center;
      }

      &__pagination {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &__active {
          color: var(--text-color);
          text-decoration: underline;
        }
      }
    }
  }
}
