@import '../../_styles/text';

.ecl-banner--text-overlay {
  .ecl-banner__content {
    //     -webkit-backdrop-filter: blur(2px);
    //     backdrop-filter: blur(2px);
    background: var(--c-bg);
    //     -webkit-box-decoration-break: clone;
    //     box-decoration-break: clone;
    //     box-sizing: border-box;
    //     margin: 0;
    padding: var(--s-m);
    //     width: 100%;
  }
  //
  // .ecl-banner__title {
  //    font: var(--f-2xl);
  //  }
  //
  //   .ecl-banner__description {
  //     font: var(--f-l);
  //   }
  //
  .ecl-banner__title,
  .ecl-banner__description {
    background: none;
    color: var(--c-d);
    padding-left: 0;
    padding-right: 0;
    @include breakpoint(lgmonitor) {
      font: var(--f-l);
    }
  }
}

.ecl-banner {
  .ecl-banner__title {
    font: var(--f-3xl);
    // line-height: 2.25rem;
    @include breakpoint(xlmonitor) {
      font: var(--f-2xl);
    }
    @include breakpoint(lgmonitor) {
      font: var(--f-xl);
    }
  }
  .ecl-banner--image-shade .ecl-banner__image:before {
    background-image: linear-gradient(to right, var(--primary-dark), var(--primary-dark) 40%, transparent);
  }

  .ecl-card__label {
    margin-bottom: 1.5rem;
  }

  .ecl-banner-timing {
    margin: 1rem 0 0;
    // color: var(--white);

    &__item {
      display: flex;
      align-items: center;
      font-size: 0.75rem;

      &:first-of-type {
        font-weight: bold;
        // margin-bottom: 0.5rem;
      }

      &:last-of-type {
        @include text(small);
      }
    }

    &__item__label {
      margin-left: 0.5rem;
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include breakpoint(tablet) {
      flex-direction: column;
    }
  }

  &__attendees-container {
    border-top: 5px solid var(--yellow);
    background-color: var(--white);
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
      color: var(--grey-dark);
      font-weight: bold;
      text-align: center;
      line-height: 150%;
      letter-spacing: 0.3px;
      max-width: 15rem;
    }
  }
}
