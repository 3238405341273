.post-item-link {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--grey-e3);
  border-top: none;

  &:hover,
  &:focus {
    background-color: var(--dark-blue-10);
  }

  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }

  &__card {
    h3 {
      font-size: 1rem;
      color: var(--blue);
    }

    &__date {
      display: flex;
      align-items: center;
      gap: 0.7rem;
      color: var(--grey-44);
    }
  }
}
