.checkbox-wrapper {
  input {
    margin: 0;
    padding: 0;
    display: grid;
    place-items: center;
    appearance: none;
    width: 1rem;
    height: 1rem;
    margin-right: 1rem;

    &[disabled] {
      opacity: 0.5;

      ~ span {
        opacity: 0.5;
      }
    }
  }

  > label {
    display: flex;
    color: var(--primary-text);
    cursor: pointer;
    line-height: 1.1;

    > span {
      min-width: 0;
    }

    &.is-error {
      color: var(--error);
    }
  }
}

.checkbox-wrapper:not(.checkbox-toggle) {
  margin: 0.8rem 0;

  input[type='checkbox'] {
    border: 2px solid var(--grey-44);

    &::after {
      content: '';
      width: 0.375rem;
      height: 0.75rem;
      margin-top: -2px;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
      border: solid transparent;
    }

    &:checked {
      background-color: var(--blue);
      border-color: var(--blue);

      &::after {
        content: '';
        width: 0.2rem;
        height: 0.4rem;
        margin-top: -2px;
        border: solid var(--white);
        border-width: 0px 2px 2px 0;
        transform: rotate(45deg);
      }
    }

    &:hover {
      border-color: var(--blue);
    }

    &:focus {
      border-color: var(--yellow);
      box-shadow: none;
      outline: none;
    }
  }

  input[type='radio'] {
    border: 0.5rem solid var(--grey-44);
    border-radius: 50%;
    transition: border-width 0.25s ease-out;
    will-change: border-width;
    border-width: 2px;

    &:checked {
      border-width: 5px;
    }
  }
}

.checkbox-wrapper.checkbox-toggle {
  --track-width: 2.75rem;
  --track-height: 1.125rem;

  > label {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

  input {
    width: var(--track-width);
    height: var(--track-height);
    border-radius: var(--track-height);
    background-color: var(--grey-brown);
    will-change: background-color;
    margin-top: 0.24rem;
    border: 2px solid transparent;

    &:checked {
      background-color: var(--blue-light);

      &::after {
        border: none;
        transform: translateX(calc(var(--track-width) - 100%)) translateY(-5px);
        background-color: var(--blue);
      }
    }

    &::after {
      content: '';
      border-radius: 50%;
      width: calc(var(--track-height) + 6px);
      height: calc(var(--track-height) + 6px);
      transform: translateX(-2px) translateY(-5px);
      background-color: var(--grey-44);
      justify-self: flex-start;
      transition: 0.25s ease-out;
      will-change: background-color, transform;
    }

    &:focus {
      outline: none;
      border: 2px solid var(--yellow);
    }
  }
}
