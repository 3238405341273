.ecl-menu__sticky {
  background-color: var(--background-color);
  position: sticky;
  z-index: 60;
  top: 0;

  .ecl-menu__list {
    background-color: var(--background-color) !important; // To overwrite EU Styling
  }
}
