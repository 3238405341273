.user-detail {
  section {
    padding: 2rem 0;
    border-bottom: 1px solid var(--silver);

    h2 {
      font-size: 1.375rem;
      line-height: 140%;
      margin-bottom: 1rem;
    }
  }

  &__information {
    &__content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 1.5rem;
      row-gap: 1.5rem;
      margin-top: 2rem;

      img {
        width: 8.125rem;
        height: 8.125rem;
        background-color: var(--grey);
        border-radius: 50%;
        object-fit: cover;
        object-position: top;
      }

      h3 {
        font-size: 1.25rem;
        line-height: 150%;
        color: var(--blue);
      }

      .user-info-line {
        display: flex;
        align-items: center;
        row-gap: 0.75rem;
        column-gap: 0.75rem;

        > div {
          display: flex;
          flex-direction: column;
          row-gap: 0.5rem;
        }

        svg,
        span {
          color: var(--grey-dark);
        }

        &:first-of-type {
          align-items: flex-start;
          margin-bottom: 1rem;
        }
      }
    }
  }

  &__bio {
    p {
      line-height: 150%;
      letter-spacing: 0.3px;
      color: var(--night);
    }
  }

  &__topics {
    > div {
      display: flex;
      flex-wrap: wrap;
      row-gap: 1rem;
      column-gap: 1rem;
    }
  }

  &__contact {
    .ecl-link {
      color: var(--night);

      &:not(&:last-of-type) {
        margin-bottom: 0.5rem;
      }
    }
  }
}
