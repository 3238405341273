@import '../../../_styles/text';

.hub-item {
  display: block;
  max-width: 348px;
  background-color: var(--white);
  border: 1px solid var(--mercury);
  padding: 0.75rem;

  img {
    width: 100%;
    height: 213px;
    object-fit: contain;
    background-color: var(--blue-grey);
    border: 1px solid var(--blue-light);
  }

  h4 {
    @include text(medium);
    font-weight: bold;
    text-align: center;
    margin: 2.5rem 0 2rem;
    color: var(--blue);
  }

  &:hover,
  &:focus {
    background-color: var(--grey-light);
  }
}
