@import '../../_styles/constants';
@import '../../_styles/spacing';
@import '../../_styles/breakpoints';

.post-detail {
  &__content {
    @include padding;
    display: flex;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    aside {
      flex-basis: $filter-width;

      p {
        color: var(--grey-44);
      }

      li {
        margin-top: 0.5rem;

        &:not(:has(button)) {
          font-weight: bold;
        }
      }

      .ecl-social-media-share__list li {
        margin-top: var(--s-l);
      }

      & > * {
        margin-top: 1rem;
      }
    }

    #top {
      flex: 1;
    }

    &__socials {
      display: grid;
      gap: 1rem;
      grid-template-columns: 130px;
    }

    &__topics {
      display: flex;
      gap: 1rem;
      margin: 1rem 0;
    }

    .picture {
      margin-bottom: 2rem;
    }
  }

  &__related {
    @include padding;

    .title {
      margin-bottom: 2rem;
    }
  }

  &__comments {
    background-color: var(--blue-grey);
    @include padding;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .textarea-wrapper {
      margin-top: 1rem;
    }
  }
}
