@import '../../../_styles/breakpoints';
@import '../../../_styles/buttons';
@import '../../../_styles/spacing';

.intro-community {
  @include padding;

  &__wrapper {
    display: flex;
    margin-top: 2.4rem;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    &__info,
    &__stats {
      .count {
        font-size: 2rem;
        font-weight: 900;
      }
    }

    &__info {
      flex: 1;

      > span {
        display: flex;
        align-items: center;

        @include breakpoint(tablet) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      p {
        &:nth-of-type(2) {
          margin-top: 1.2rem;
        }
      }

      .ecl-button {
        padding: 0.75rem 0.4rem;

        @include breakpoint(tablet) {
          padding-left: 0;
        }

        .ecl-icon {
          color: var(--blue);
          transform: rotate(90deg);
        }
      }
    }

    &__stats {
      flex: 1;
      display: flex;
      margin-left: 3.2rem;
      background-color: var(--blue-grey);
      border: 1px solid var(--blue-light);
      padding: 2rem;
      padding-right: 5rem;

      @include breakpoint(tablet) {
        flex-direction: column;
        margin: 1.2rem 0 0 0;
        padding: 1rem;
        text-align: center;
      }

      .ecl-icon {
        color: var(--blue-dark-black);
      }

      &__posts,
      &__members {
        * {
          &:not(:first-child) {
            margin-top: 1.2rem;

            @include breakpoint(tablet) {
              margin-top: 0.4rem;
            }
          }
        }
      }

      &__posts {
        flex: 1;
      }

      &__members {
        flex: 1;
        margin-left: 1.5rem;

        @include breakpoint(tablet) {
          margin: 1.2rem 0 0 0;
        }
      }
    }
  }
}
