.create-post-modal {
  &__label {
    font-weight: bold;
    font-size: 1.125rem;
    margin-bottom: 1.2rem;
  }

  .input-wrapper,
  .quill {
    margin-bottom: 1.2rem;
  }

  input::placeholder {
    font-style: italic;
    color: #6A6A6A;
  }

  &__actions {
    margin-top: 2.75rem;
    display: flex;
    justify-content: flex-end;
  }
}
