@import '../../../_styles/text';

.related-sessions {
  margin-top: 2.5rem;

  h4 {
    @include text(medium);
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .session-item {
    margin-bottom: 1rem;
  }

  &__empty {
    color: var(--grey);
  }

  &__button {
    display: block;
    width: fit-content;
    margin: 1rem auto 0;
  }
}
