@import '../../../_styles/text';

.list {
  margin: 2rem 0;

  .ecl-card {
    height: auto;
  }

  &__title {
    display: flex;
    align-items: center;

    h2,
    span {
      @include heading(3);
    }

    span {
      color: var(--grey);
      margin-left: 0.5rem;
    }
  }

  &__events {
    margin: 2rem 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }

  &__show-more {
    color: var(--grey);
    display: flex;
    align-items: center;

    @include breakpoint(phone) {
      flex-direction: column;
      justify-content: center;
    }

    &__divider {
      margin: 0 0.5rem;
    }
  }
}
