@import '../../_styles/spacing';

.events {
  @include padding;
  display: flex;
  flex-direction: column;

  > .ecl-container .show-more {
    margin: 0.75rem 0 0.75rem auto;
  }
}
