.ecl-menu:not(.ecl-menu--forced-mobile) .ecl-menu__item {
  border-inline-start: none;
  border-inline-end: none;

  // a {
  //   font-weight: bold;
  // }
}

.ecl-menu__inner {
  z-index: 60;
}
