button.ecl-link {
  display: block;
  font-size: 1rem;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.button--loading {
  position: relative;
  pointer-events: none;
  color: transparent;

  .spinner-wrapper {
    margin: 0;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
