@import '../../../_styles/breakpoints';
@import '../../../_styles/text';
@import '../../../_styles/spacing';

.organiser-info {
  @include padding;
  background-color: var(--blue-grey);

  h2 {
    @include heading(3);
  }

  > div > div {
    margin-top: 0.5rem;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    .picture {
      min-width: 30%;
      max-width: 30%;

      @include breakpoint(tablet) {
        min-width: 100%;
      }
    }
  }

  &__content {
    a {
      margin-top: 1.5rem;
    }
  }
}
