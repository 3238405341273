@import '../../_styles/constants';
@import '../../_styles/buttons';

.scroll-to-top {
  @include unstyled-button;
  background-color: var(--primary);
  width: 7rem;
  height: 7rem;
  color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 5rem;
  z-index: 60;
  cursor: pointer;
  box-shadow: 5px 5px 0 2px var(--yellow);
  -webkit-box-shadow: 5px 5px 0 2px var(--yellow);
  -moz-box-shadow: 5px 5px 0 2px var(--yellow);
}
