@import '../../_styles/text';

.ecl-carousel {
  &__toggle {
    border: none;
  }

  &__empty {
    background-color: var(--blue-dark);
    height: 25rem;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      @include text(lead);
      color: var(--white);
    }
  }
}
