// Make sure we import all "global" styling files here so that they are imported only once
@import './_styles/breakpoints';
@import './_styles/buttons';
@import './_styles/constants';
@import './_styles/colors';
@import './_styles/detail';
@import './_styles/spacing';
@import './_styles/reset';
@import './_styles/text';
@import './_styles/userDetail';

html,
body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--black);
  background-color: var(--white);
}

body {
  @include text(medium);
}

p {
  white-space: pre-line;
}

button {
  cursor: pointer;
  background: inherit;
  color: var(--black);
  font-family: $font-family;

  &.plain {
    border: 0;
    background: transparent;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0;
    cursor: pointer;
    outline: 0;

    i {
      color: var(--white);
    }
  }
}

a {
  color: inherit;
  text-decoration: none;

  .icon {
    color: inherit;
  }
}

// Overrides react quill
.quill {
  a {
    color: var(--primary) !important;
    font-weight: bold;
  }
}

.visually-hidden:not(:focus:not(.focus-visible)):not(:active) {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
