@import '../../../_styles/spacing';
@import '../../../_styles/text';

.steps {
  @include padding;
  background-color: var(--blue-grey);

  &__single {
    display: flex;
    align-items: center;
    padding: 1.75rem 1rem;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:nth-child(even) {
      background-color: var(--white);
    }

    .circle {
      margin-right: 1rem;
    }

    &__content {
      flex: 1;

      h3 {
        @include text(medium);
        font-weight: bold;
      }
    }
  }
}
