:root {
  --toastify-font-family: roboto, sans-serif;
  --toastify-color-info: var(--primary);
  --toastify-color-success: var(--green);
  --toastify-color-warning: var(--yellow);
  --toastify-color-error: var(--red);
  --toastify-text-color-light: var(--grey-dark);
  --toastify-icon-color-info: var(--primary);
  --toastify-icon-color-success: var(--green);
  --toastify-icon-color-warning: var(--yellow);
  --toastify-icon-color-error: var(--red);
}
