@import '../../../_styles/constants';

.input {
  width: 100%;
  outline: none;
  text-align: left;
  padding: 0.66rem;
  background: var(--white);
  border: 1px solid var(--grey-dark);
  color: var(--text-color);
  box-sizing: border-box;
  height: $input-height;

  &::placeholder {
    color: var(--placeholder-color);
  }

  &:disabled {
    opacity: $disabled-opacity;
    background-color: var(--disabled-background-color);
  }

  &.error {
    color: var(--error-placeholder-color);
    border-color: var(--error-border-color);
    background-color: var(--error-background-color);

    &::placeholder {
      color: var(--error-placeholder-color);
    }

    &:focus {
      border-color: var(--red);
    }
  }

  &:focus {
    border-color: var(--primary);
  }

  &::placeholder {
    color: var(--placeholder-color);
  }
}
