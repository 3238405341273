.event-item-link {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.25), -1px 1px 0px rgba(0, 0, 0, 0.25);

  &:hover,
  &:focus {
    background-color: var(--dark-blue-10);
  }

  &__image {
    width: 5rem;
    height: 5rem;
  }

  &__card {
    h3 {
      font-size: 1rem;
      color: var(--blue);
    }

    &__date {
      display: flex;
      gap: 0.7rem;
      color: var(--grey-44);
      align-items: center;
    }
  }
}
