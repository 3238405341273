@import '../../../_styles/spacing';

.featured-events {
  @include padding;

  &__title {
    padding: 2rem 0 1.5rem 0;
    display: flex;
    justify-content: space-between;

    a {
      color: var(--primary);
      display: flex;
      align-items: center;
      font-weight: bold;

      .ecl-icon {
        margin-left: 0.5rem;
      }
    }
  }
}
