@import '../../_styles/spacing';
@import '../../_styles/breakpoints';
@import '../../_styles/buttons';
@import '../../_styles/constants';

.filter-bar {
  min-width: $filter-width;
  max-width: $filter-width;
  border-right: 1px solid var(--grey);
  padding: 1rem 1rem 1rem 0;

  @include breakpoint(desktop) {
    padding: 2rem;
    border: 0;
  }

  &__title {
    display: flex;

    button {
      @include unstyled-button;
      color: var(--primary-dark);
      font-weight: bold;
      margin-left: 0.5rem;
    }
  }

  .ecl-form-label {
    margin: 1rem 0 0.5rem 0;
  }

  .checkbox-toggle {
    margin-top: 0.5rem;

    label {
      display: flex;
      justify-content: space-between;
    }
  }

  h4 {
    margin: 2rem 0 0.5rem;
  }

  &__modal {
    position: relative;
    min-width: $tablet-breakpoint;
    max-width: $desktop-breakpoint;
    padding: 0 3rem;
    background-color: var(--white);
    max-height: 90vh;
    overflow-y: auto;

    > .ecl-button {
      position: absolute;
      right: 0.75rem;
      top: 0.75rem;
    }
    @include breakpoint(tablet) {
      min-width: 100%;
      min-height: 100vh;
    }
  }
}
