@import '../_styles/spacing';
@import '../_styles/breakpoints';

.workshops {
  &__header {
    @include padding;

    > p {
      margin: 3rem 0 5rem;
      max-width: 33.125rem;
    }
  }

  &__content {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: var(--blue-grey);

    &__workshop {
      padding: 1rem;
      border: solid 1px var(--grey);

      h3:first-of-type {
        margin-bottom: 2rem;
      }

      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }

      .markdown {
        flex-direction: column;
        align-items: flex-start;
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include breakpoint(tablet) {
          flex-direction: column;
          gap: 2rem;
        }
      }

      &__date,
      &__time {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        color: var(--grey-dark);
        font-weight: bold;

        .icon {
          margin-right: 0.5rem;
        }
      }

      &__time {
        span:last-child {
          margin-left: 0.5rem;
          font-weight: normal;
        }
      }

      button:last-of-type {
        margin-left: 1rem;
      }
    }

    .show-more {
      margin: 2rem auto 0 auto;
    }
  }

  &__key-points {
    @include padding;

    &__item {
      border-left: 0.5rem solid var(--blue);
      padding: 1rem 0 1rem 1.2rem;
      max-width: 18rem;

      h4 {
        color: var(--blue);
        white-space: no-wrap;
      }

      p {
        display: flex;
        white-space: nowrap;
        align-items: center;

        a {
          padding: 0 0 0 0.2rem;
          text-decoration: underline;

          svg {
            color: var(--blue);
          }
        }
      }
    }
  }
}
