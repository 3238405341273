@import '../../_styles/spacing';
@import '../../_styles/breakpoints';
@import '../../_styles/text';

.live-sessions {
  @include padding;

  .tabbar {
    margin: 2rem 0;
  }

  &__active {
    background-color: var(--grey-light);
    border: 1px solid var(--grey);
    padding: 1rem;

    &__details {
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include breakpoint(tablet) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__date,
      &__time {
        display: flex;
        align-items: center;
        margin-top: 1rem;
        color: var(--grey-dark);
        font-weight: bold;
      }

      .icon {
        margin-right: 0.5rem;
      }
    }
  }
}
