$font-family: Arial, sans-serif;
$font-weight-light: 100;
$font-weight-normal: 400;
$font-weight-bold: 700;

$disabled-opacity: 0.75;
$border-radius: 0.25rem;

$input-height: 2.75rem;
$input-icon-height: 2.2rem;
$input-label-bottom-margin: 0.5rem;

$event-item-height: 7.0625rem;

$filter-width: 23rem;
