.spinner-grid {
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
