@import '../../_styles/buttons';

.tabbar {
  border-bottom: 1px solid var(--grey);
  margin-bottom: 1rem;

  > button {
    @include unstyled-button;
    padding: 1rem;

    &.active-tab {
      font-weight: bold;
      border-top: 4px solid var(--primary-dark);
      border-left: 1px solid var(--grey);
      border-right: 1px solid var(--grey);
    }
  }
}
