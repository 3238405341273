.person {
  width: fit-content;
  position: relative;

  .checkbox-wrapper {
    position: absolute;
    right: -1.4rem;
    top: -1.4rem;
  }

  &__network-icon {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0;
  }

  &__name {
    color: var(--primary);
    font-weight: bold;
  }

  &__organisation {
    color: var(--grey-dark);
    font-weight: normal;
  }
}

.person__container {
  background-color: var(--white);
  border: 1px solid var(--mercury);
  width: 13.75rem;
  height: 18rem;

  .ecl-button__container {
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    row-gap: 1rem;
  }

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    object-fit: cover;
    object-position: top;
  }

  .ecl-icon {
    fill: none;
  }
}
