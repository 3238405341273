.project-item {
  position: relative;
  width: 100%;
  padding: 0.75rem 0.75rem 1rem;
  border: 1px solid var(--mercury);
  background-color: var(--white);

  &__image {
    width: 100%;
    object-fit: cover;
    height: 216px;
    margin-bottom: 1rem;
  }

  &__title {
    font-weight: bold;
    font-size: 1rem;
    color: var(--blue);

    &:hover,
    &:focus {
      color: var(--blue-dark);
      text-decoration: underline;
    }
  }

  .favorite-button {
    top: 1.5rem;
    right: 1.5rem;
  }
}
