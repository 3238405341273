.hub-detail__navigation {
  > span {
    font-size: 1.125rem;
    color: var(--grey-44);
  }

  li {
    .ecl-button__label {
      margin-left: 0.5rem;
      position: relative;

      &:after {
        content: '';
        border-radius: 50%;
        width: 4px;
        height: 4px;
        position: absolute;
        left: -1rem;
        background-color: var(--blue);
      }
    }
  }
}
