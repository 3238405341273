.create-groupchat {
  margin-top: 0;
  border: 1px solid var(--primary-light);
  background-color: var(--blue-grey);
  width: 100%;

  &__selected-users {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      width: 100%;

      > .ecl-button {
        margin: 1rem 1.6rem 0 0;
        font-size: 0.75rem;
        white-space: nowrap;
        padding: 0.5rem 1rem;
        border-radius: 16px;

        &__container {
          justify-content: space-between;
        }
      }
    }

    .send-message {
      white-space: nowrap;
      align-self: flex-start;
    }
  }
}
