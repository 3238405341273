@import '../../_styles/spacing';
@import '../../_styles/breakpoints';
@import '../../_styles/buttons';

.projects {
  @include padding;

  &__content {
    display: flex;
    padding-top: 2rem;

    @include breakpoint(desktop) {
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }

    &__item {
      &__metadata {
        color: var(--grey-44);
      }
    }

    &__items {
      &-wrapper {
        padding: 1rem;
      }

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 1rem;
      column-gap: 2rem;

      @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        column-gap: 1rem;
      }

      &__total-count {
        margin-bottom: 1.8rem;
      }

      &__load-more {
        margin: 1rem 0 0;
        display: flex;
        justify-content: center;
        align-items: center;

        > button {
          padding-left: 0.5rem;
        }
      }
    }

    &__empty {
      color: var(--grey-44);
    }
  }
}
