.ecl-page-header {
  margin: 0;

  &__description {
    max-width: 46rem;
  }

  &__description-container {
    margin-bottom: var(--s-s);
  }
}
