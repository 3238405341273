.event-map {
  max-width: 530px;
  border: 1px solid var(--grey-brown);
  background-color: var(--grey-light);

  .leaflet-container {
    width: 100%;
    height: 309px;
    z-index: 1;
  }
}
