.project-item-link {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--grey-e3);
  border-top: none;

  img {
    width: 5rem;
    height: 5rem;
  }

  h3 {
    font-size: 1rem;
    color: var(--blue);
  }
}
