@import '../../../_styles/text';
@import '../../../_styles/breakpoints';
@import '../../../_styles/spacing';

.event-detail-overview {
  .title {
    margin-top: 2rem;
  }

  &__about {
    @include padding;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    .picture {
      min-width: 50%;
      max-width: 50%;
      height: fit-content;

      @include breakpoint(tablet) {
        min-width: 100%;
      }
    }

    h2 {
      margin-bottom: 1rem;
    }

    h3 {
      margin: 1rem 0;
    }

    &__topics {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
    }
  }

  &__links {
    @include padding;
    display: grid;
    row-gap: 2rem;
    column-gap: 5rem;
    grid-template-columns: 1fr 1fr;

    a {
      display: flex;
      flex-direction: column;
      border-left: 5px solid var(--blue);
      padding: 1rem 1.5rem;

      h4 {
        font-size: 1.125rem;
        color: var(--blue);
      }

      &:hover,
      &:focus {
        background-color: var(--grey-light);
      }
    }

    @include breakpoint(tablet) {
      grid-template-columns: 1fr;
    }
  }
}
