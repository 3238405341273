@import '../../../_styles/text';

.related-hubs {
  h4 {
    @include text(medium);
    font-weight: bold;
    margin-bottom: 1rem;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  &__empty {
    color: var(--grey);
  }

  &__button {
    display: block;
    width: fit-content;
    margin: 1rem auto 0;
  }
}
