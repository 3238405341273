.hubs {
  h2 {
    margin: 3rem 0;
  }

  p {
    max-width: 45rem;
    &:first-of-type {
      margin-bottom: 1rem;
    }

    &:last-of-type {
      margin-bottom: 3.625rem;
    }
  }

  &__overview {
    padding: 2rem 0;
    background-color: var(--blue-grey);

    h3 {
      margin-bottom: 2rem;
    }
    &__items {
      display: flex;
      flex-wrap: wrap;
      row-gap: 3rem;
      column-gap: 2rem;
    }
  }
}
