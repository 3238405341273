.session-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 1.5rem 1rem;
  border: 1px solid var(--primary-light);
  background-color: var(--blue-grey);

  &__title {
    font-weight: bold;
    font-size: 1rem;
    color: var(--blue);

    &:hover,
    &:focus {
      color: var(--blue-dark);
      text-decoration: underline;
    }
  }

  &__channel {
    display: flex;
    align-items: center;
    width: fit-content;
    margin: 0.75rem 0 1.5rem;
    padding: 0.5rem 1rem;
    color: var(--grey-dark);
    border: 1px solid var(--grey-brown);
    background-color: var(--grey-light);

    > svg {
      margin-right: 0.5rem;
    }
  }

  &__timing {
    display: flex;
    align-items: center;
    color: var(--grey-dark);
    margin-bottom: 0.75rem;

    &:last-of-type {
      margin-bottom: 1.5rem;
    }

    > svg {
      margin-right: 0.5rem;
    }
  }

  > button {
    align-self: flex-end;
    background-color: var(--blue-grey);
  }
}
