@import '../../_styles/breakpoints';

.site-header {
  position: sticky;
  top: 0;
  z-index: 60;
}

@include breakpoint(tablet) {
  .ecl-site-header-core__action {
    align-items: flex-end;
  }
}

.notifications-indicator {
  font-weight: normal;
  display: flex;
  align-items: center;
  text-decoration: none;

  > span {
    color: var(--blue);
  }

  &__counter {
    background-color: var(--blue);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    color: var(--white);
    margin-right: 0.5rem;
  }
}
