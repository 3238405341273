@import '../_styles/colors';
@import '../_styles/spacing';

/**
 * Overriding TalkJS styles is not possible here because this is working with an iframe.
 * Therefore, TakjJS offers a "Theme editor" to change some styling: https://talkjs.com/dashboard/t1LcfAEO/themes/default.
 */

.conversations {
  @include padding;

  > p {
    margin: 2rem 0;
    max-width: 43rem;
  }

  .conversations-wrapper {
    position: relative;
    margin-top: 2rem;
    border: 1px solid var(--grey-brown);
    margin-bottom: 10rem;
    padding: 0;

    .chatbox-container {
      height: 60rem;
      background-color: var(--white);
    }
  }
}
