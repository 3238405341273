.comment {
  background-color: var(--white);
  border: 1px solid var(--grey-e3);
  padding: 1rem;
  margin-top: 1rem;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    img {
      margin-right: 0.5rem;
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
    }

    .ecl-icon {
      color: white;
      margin-right: 0.5rem;
    }

    &__user {
      color: var(--primary);
      font-weight: bold;
      display: block;
    }

    &__time {
      color: var(--grey-44);
    }
  }

  &__actions {
    display: flex;
    align-items: center;

    & > span {
      color: var(--grey-44);
    }
  }
}
