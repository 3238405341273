@import '../../_styles/buttons';

.ecl-gallery {
  &__item-link {
    @include unstyled-button;
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }
}

.video-placeholder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
}
