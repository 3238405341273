@import '../../../_styles/breakpoints';
@import '../../../_styles/text';
@import '../../../_styles/spacing';

.attend-info {
  @include padding;

  > div {
    display: grid;
    row-gap: 1rem;
    column-gap: 3rem;
    grid-template-columns: 1fr 1fr;

    @include breakpoint(tablet) {
      display: flex;
      flex-direction: column;
    }
  }

  h2 {
    @include heading(3);
    margin-bottom: 1.5rem;
  }

  .ecl-button {
    margin-top: 1.5rem;

    &__green {
      background-color: var(--green-dark);
      color: var(--white);
    }
  }

  &__info {
    height: fit-content;
    background-color: var(--white);
    border: 1px solid var(--blue-light);
    padding: 2rem;
    display: flex;
    gap: 1.375rem;

    h3 {
      @include heading(1);
      margin: 1rem 0;
    }

    span {
      font-size: 1.125rem;
    }

    &__bold {
      font-weight: bold;

      &:nth-of-type(2) {
        margin-top: 1rem;
      }
    }

    .ecl-icon--xs {
      margin-right: 0.5rem;
    }
  }
  .event-map {
    margin-top: 2rem;
  }
}
