@import '../../_styles/breakpoints';
@import '../../_styles/buttons';
@import '../../_styles/spacing';

.about-the-community__key-points {
  @include padding;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.6rem;

  @include breakpoint(tablet) {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: auto;
  }

  &__item {
    @include unstyled-button;
    border-left: 0.5rem solid var(--blue);
    padding: 0.4rem 0 0.4rem 1.2rem;
    max-width: 18rem;

    h4 {
      color: var(--blue);
      white-space: no-wrap;
    }
  }
}

.ask-the-community {
  background-color: var(--blue-grey);
  @include padding;
}
