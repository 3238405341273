.created {
  li {
    display: flex;
    flex-wrap: wrap;
  }

  .ecl-card__info-label {
    margin-left: 0;
    margin-bottom: 0.5rem;
  }

  &__user {
    display: flex;
    align-items: center;
    color: var(--primary);
    font-weight: bold;

    &__image {
      margin-right: 0.5rem;
    }

    img {
      height: 2rem;
      width: 2rem;
      border-radius: 50%;
    }

    .ecl-icon {
      color: white;
    }
  }
}
