@import '../../../_styles/buttons';

.faq {
  border: 1px solid var(--grey);
  margin-top: 1rem;

  &__trigger {
    @include unstyled-button;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;
    font-weight: bold;

    &__open {
      background-color: var(--yellow);
    }
  }

  &__markdown {
    background-color: var(--grey-light);
    padding: 1rem;
  }
}
