@import '../../_styles/spacing';
@import '../../_styles/breakpoints';

.project-detail {
  @include padding;

  .back-navigation {
    margin: 1.5rem 0;
  }

  &__title {
    position: relative;

    &__button {
      position: absolute;
      right: 0;
      top: -1rem;
    }
  }

  &__about {
    @include padding;
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    max-height: 30rem;

    @include breakpoint(tablet) {
      flex-direction: column;
      max-height: 60rem;
    }

    .picture {
      min-width: 50%;
      max-width: 50%;

      @include breakpoint(tablet) {
        min-width: 100%;
      }
    }
  }

  &__content {
    h3 {
      margin-bottom: 1rem;
    }

    &__gallery {
      border-bottom: 1px solid var(--grey-brown);

      .ecl-gallery {
        margin-bottom: 0;
      }
    }

    &__collaborators {
      &.show-top-border {
        border-top: 1px solid var(--grey-brown);
        padding-top: 1rem;
      }
    }

    &__details {
      padding: 1.5rem 1rem;
      background-color: var(--grey-light);
      border: 1px solid var(--grey-brown);

      &__wrapper {
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        margin-bottom: 1rem;

        dd {
          font-weight: bold;
          margin-left: 1rem;
        }
      }

      &__topics {
        margin-top: 1rem;
        display: flex;
        gap: 0.5rem;
        flex-wrap: wrap;
      }
    }
  }
}
