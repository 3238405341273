.search-form {
  display: flex;
  flex-direction: column;

  .ecl-form-label {
    // color: var(--text-color);
    // font-size: 1.125rem;
    // margin-bottom: 1.5rem;
  }

  // .ecl-button--search.ecl-search-form__button {
  //   margin: 0;
  //   height: 2.75rem;
  // }
}
