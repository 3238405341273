@import '../../_styles/spacing';
@import '../../_styles/breakpoints';

.join-platform {
  @include padding;

  .join-platform__actions {
    display: flex;
    gap: 1em;
  }
  &__content {
    padding-top: 2rem;
    display: flex;
    gap: 2rem;

    @include breakpoint(desktop) {
      flex-direction: column;
    }

    p {
      margin-bottom: 2rem;
    }

    // .ecl-button {
    //   margin-right: 2rem;
    // }
  }
}
