.create-conversation-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &__selected-users {
    display: flex;
    flex-wrap: wrap;

    .ecl-button {
      margin-top: 1rem;
      font-size: 0.75rem;
      white-space: nowrap;
      padding: 0.5rem 1rem;
      border-radius: 16px;

      &:not(:first-of-type) {
        margin-left: 1rem;
      }
    }
  }

  .input-wrapper {
    margin-top: 2rem;
  }

  &__info {
    color: var(--grey-black);
    margin-top: 1rem;
  }

  &__actions {
    margin-top: 2.75rem;
    display: flex;
    justify-content: flex-end;

    .ecl-button {
      &:nth-of-type(2) {
        margin-left: 1rem;
      }
    }
  }
}
