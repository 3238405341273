@import '../../../_styles/breakpoints';

.engage {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;

  .ecl-button--secondary {
    margin-right: 1rem;
  }

  @include breakpoint(monitor) {
    flex-direction: column;
    gap: 1rem;
  }

  .ecl-social-media-share {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .ecl-social-media-share__description {
    margin-bottom: 0;
  }

  .ecl-social-media-share__item {
    margin-top: 0;
  }
}
