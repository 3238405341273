.topic-item {
  display: block;
  padding: 1rem;
  border: 1px solid var(--blue-light);
  height: 144px;

  &__title {
    font-weight: bold;
    color: var(--blue);
  }

  p {
    font-size: 0.875rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

.topic__carousel-wrapper {
  position: relative;
  padding-top: 1.5rem;

  .show-more {
    position: absolute;
    right: 0;
    top: 1.5rem;
  }

  .topic__carousel {
    margin: 2rem auto 3rem;
    width: 94%;
  }
}

.topic-banner {
  padding: 3rem 0;
  background-color: var(--blue-grey);

  .topic__description {
    margin-top: 0.5rem;
    font-size: 1.125rem;
  }
}
