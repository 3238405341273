.network-item-link {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--grey-e3);
  border-top: none;

  img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }

  .ecl-icon {
    fill: none;
  }

  &__card {
    h3 {
      font-size: 1rem;
      color: var(--blue);
    }

    p {
      color: var(--grey-44);
    }
  }
}
