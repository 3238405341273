.notifications {
  margin: 3.125rem auto;

  &__back {
    margin-bottom: 1rem;
    padding: 0;
  }

  .notifications-container {
    border: 1px solid var(--grey-e3);
    background-color: var(--grey-light);
    padding: 0;

    h4 {
      font-size: 1rem;
      color: var(--grey-dark);
      display: flex;
      align-items: center;
      padding: 1rem;
      border-bottom: 2px solid var(--grey);

      > span {
        background-color: var(--blue);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.5rem;
        height: 1.5rem;
        color: var(--white);
        margin-left: 0.5rem;
      }
    }

    .no-notifications {
      margin: 3rem 0 3rem 1rem;
    }
  }

  .load-more-container {
    padding: 1rem 0;
  }

  &__load-more {
    margin-bottom: 7.5rem;

    .ecl-icon {
      transform: rotate(180deg);
    }

    .is-closed {
      .ecl-icon {
        transform: rotate(0deg);
      }
    }
  }
}
