@import '../_styles/breakpoints';
@import '../_styles/colors';
@import '../_styles/constants';

$chat-background-color: var(--white); // Is in sync with BE added styling

.spinner-wrapper {
  z-index: 1;
}

.chat-wrapper {
  width: 100%;
  padding: 0;

  .ecl-button__container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: $chat-background-color;

    .spinner-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
    }

    .chat-header {
      width: 100%;
      text-align: left;
      padding: 1.5rem 2rem;
      background-color: var(--blue-grey);
      color: var(--blue);
      font-weight: $font-weight-bold;
      font-size: 1.125rem;
    }
  }

  iframe {
    height: 450px;
    width: 100%;
  }
}

.chat--unauthenticated {
  pointer-events: none;
  -webkit-filter: blur(2px);
  filter: blur(2px);
}
