@import '../../_styles/breakpoints';
@import '../../_styles/colors';
@import '../../_styles/text';

$talkjs-mobile-breakpoint: 640px; // breakpoint defined by TalkJS
$chatbox-left: calc(max(30%, 300px));
$talkjs-actions-height: 121px; // Height is set in their editor

.conversation-actions-border {
  display: block;
  width: 1px;
  background-color: var(--grey-brown);
  position: absolute;
  left: $chatbox-left;
  top: 0;
  bottom: 0;

  @media (max-width: $talkjs-mobile-breakpoint) {
    display: none;
  }
}

.conversation-actions {
  @include text(medium);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  justify-content: space-between;
  background-color: var(--blue-grey);
  padding: 1rem 2rem;
  position: absolute;
  height: $talkjs-actions-height;
  top: 0;
  left: $chatbox-left;
  right: 0;
  border-left: 1px solid var(--white);
  border-bottom: 1px solid var(--grey-brown);

  @media (max-width: $monitor-breakpoint) {
    @include text(medium);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 1rem;
  }

  @media (max-width: $talkjs-mobile-breakpoint) {
    top: 4.3rem;
    left: 0;
    height: 13.2rem;
    border: 1px solid var(--white);
  }

  &__text {
    font-size: 2rem;
    flex: 1;
  }

  .conversation-actions__buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    .conversation-actions-dropdown {
      margin-left: 1rem;
    }
  }
}
