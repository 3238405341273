@import '../../../_styles/breakpoints';
@import '../../../_styles/text';

.next-session {
  h4 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  > div {
    padding: 0.75rem;
    background-color: var(--blue-grey);
    border: 1px solid var(--blue-light);

    h4 {
      @include text(medium);
      font-weight: bold;
      color: var(--blue);
    }

    button {
      margin-top: 1rem;
    }
  }

  &__date,
  &__time {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    color: var(--grey-dark);
    font-weight: bold;
  }

  .icon {
    margin-right: 0.5rem;
  }

  &__empty {
    color: var(--grey);
  }
}
