@import '../../_styles/spacing';

.user-swiper {
  @include padding;
  background-color: var(--blue-grey);

  .swiper {
    margin-top: 1.5rem;
  }
}
