.featured_post {
  position: relative;
  display: flex;
  height: 35rem;
  flex: 1;
  flex-direction: column;

  .ecl-card {
    &__info-container {
      margin-top: 0;
    }

    &__label {
      width: fit-content;
      margin-bottom: 0.5rem;
    }

    &__body {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: space-between;
    }

    &__title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  &__user {
    color: var(--primary);
    font-weight: bold;
  }

  &__content {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .favorite-button {
    top: 1.5rem;
    right: 1.5rem;
  }
}
