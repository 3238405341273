.profile-list {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--blue-grey);
    box-shadow: 0px 2px 0px var(--grey);
    border: 1px solid var(--grey-e3);
    padding: 1rem;

    .ecl-button {
      padding: 0; // To overwrite EU styling
    }

    &__title {
      font-size: inherit;
      color: var(--grey-dark);
    }

    &__button__icon {
      margin-left: 0.5rem;
      transform: rotate(90deg);
    }
  }

  &__content {
    .event-item-link {
      box-shadow: none;
      border: 1px solid var(--grey-e3);
      border-top: none;
    }

    &__empty {
      border: 1px solid var(--grey-e3);
      border-top: none;
      color: var(--grey-dark);
      display: flex;
      padding: 1rem;
    }
  }
}
