@import '../../../_styles/constants';

.textarea-wrapper {
  .textarea {
    width: 100%;
    outline: none;
    border-radius: none;
    padding: 0.66rem;
    border: 1px solid var(--grey-dark);
    font-family: $font-family;
    box-sizing: border-box;
    resize: vertical;

    &:focus {
      border: 1px solid var(--primary);
    }

    &:disabled {
      opacity: $disabled-opacity;
      background-color: var(--disabled-background-color);
    }

    &::placeholder {
      color: var(--placeholder-color);
    }

    &.error {
      color: var(--red);
      border-color: var(--red);

      &::placeholder {
        color: var(--black);
      }
    }
  }

  .character-limit {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(-grey);
    font-size: 1.4rem;

    &.reached {
      color: var(--red);
    }
  }
}
