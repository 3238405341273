.picture {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--grey-brown);
  background-color: var(--grey-light);

  figcaption {
    padding: 0.75rem;
  }
}
