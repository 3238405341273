@import '../_styles/spacing';

.profile {
  @include padding;

  .user-detail {
    .title {
      display: flex;
      justify-content: space-between;
    }
  }

  &__availabilities {
    background-color: var(--blue-grey);
    border: 1px solid var(--blue-light);
    padding: 2rem !important;

    h2 {
      color: var(--primary-dark);
    }

    button {
      margin-top: 1rem;
    }
  }

  &__availability {
    background-color: var(--blue-grey);
    padding: 1rem;
    border: 1px solid var(--blue-light);

    h3 {
      color: var(--grey-dark);
      font-size: 1rem;
    }

    .input-wrapper {
      margin-top: 1rem;

      label {
        display: flex;
        justify-content: space-between;

        span {
          white-space: nowrap;
        }
      }
    }
  }
}
