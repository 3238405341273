@import '../../_styles/constants';
@import '../../_styles/text';

.input-wrapper {
  &.required .input-wrapper-label:after {
    content: '*';
    font-size: 2.5rem;
    color: var(--red);
    margin-left: 0.3rem;
    margin-top: 0.5rem;
  }

  .input-wrapper-label:not(:last-child) {
    display: flex;
    align-items: center;
    height: $input-icon-height;
    margin-bottom: $input-label-bottom-margin;
    color: var(-grey-dark);
    font-weight: bold;
    font-size: 1.125rem;

    > :not(:first-child) {
      margin-left: 0.75rem;
    }

    i,
    svg {
      width: $input-icon-height;
      height: $input-icon-height;
    }
  }

  .error-message {
    padding-top: 1rem;
  }
}

.ui.input,
.ui.selection.dropdown {
  min-width: 100%;
}
