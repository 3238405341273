@import '../../_styles/colors';
@import '../../_styles/constants';

.conversation-actions-dropdown {
  position: relative;

  &__toggle {
    height: 2.75rem;
  }

  &__options {
    position: absolute;
    top: 3rem;
    right: 0;
    background-color: var(--white);
    width: 15rem;
    border: 1px solid var(--grey-brown);

    button {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 1rem;
      position: relative;
      color: var(--grey-dark);

      &:hover,
      &:focus {
        background-color: var(--grey-light);
      }

      &:not(:first-child) {
        border-top: 1px solid var(--grey-brown);
      }
    }
  }
}
