.community-navigation {
  background-color: var(--blue-light);

  &.ecl-menu:not(.ecl-menu--forced-mobile) {
    .ecl-menu__list {
      background-color: var(--blue-light);
      a {
        color: var(--blue-dark);
      }

      .ecl-menu__item--current .ecl-menu__link {
        background-color: var(--white);
      }
    }
  }

  .ecl-menu__open {
    color: var(--blue-dark);
  }

  .ecl-menu__inner {
    background-color: var(--white);
  }
}
