@import '../../../_styles/breakpoints';
@import '../../../_styles/constants';

.events-header {
  padding: 2.25rem 0;
  display: flex;
  flex-wrap: wrap;
  gap: 3.875rem;

  &__topics,
  &__search {
    flex: 1;
  }

  &__topics {
    h3 {
      font-size: 1.125rem;
      margin-bottom: 1.5rem;
    }

    &__container {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      & .active {
        background-color: var(--primary);
      }
    }
  }

  @include breakpoint(tablet) {
    flex-direction: column;
  }

  &__search {
    position: relative;

    &__options {
      position: absolute;
      width: 100%;
      max-height: calc(3 * $event-item-height);
      overflow-y: auto;
      background-color: var(--white);
      box-shadow: 0 4px 10px var(--dark-blue-20);
    }
  }
}
