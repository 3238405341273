.auth-modal {
  &__actions {
    margin-top: 2rem;
    display: flex;
    gap: 1rem;

    .ecl-button {
      .ecl-icon {
        transform: rotate(90deg);

        path {
          fill: var(--black);
        }
      }
    }

    // a {
    //   &:nth-of-type(2) {
    //     margin-left: 1rem;
    //   }
    // }
  }
}
