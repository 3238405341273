.invite-modal {
  &__wrapper {
    display: flex;
    gap: 2rem;

    &__users {
      border: 1px solid var(--grey);
      padding: 1rem;

      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:not(:last-of-type) {
          margin-bottom: 1rem;
        }
      }
    }

    &__selected {
      background: var(--grey-light);
      border: 1px solid var(--grey);
      padding: 1rem;

      .speaker:not(:last-of-type),
      p {
        margin-bottom: 1rem;
      }
    }
  }

  &__external {
    background-color: var(--blue-grey);
    padding: 1rem;
    border: 1px solid var(--primary-light);
    margin-top: 2rem;

    p {
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }

  &__actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
