@import '../../../_styles/breakpoints';
@import '../../../_styles/buttons';

.session {
  // &__title {
  //   color: var(--primary-dark);
  //   font-weight: bold;
  // }
  //
  &__date,
  &__time,
  &__actions {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  &__date,
  &__time {
    color: var(--grey-dark);
    font-weight: bold;

    .icon {
      margin-right: 0.5rem;
    }
  }

  &__time {
    &__divider {
      margin: 0 0.5rem;
    }

    span:last-child {
      margin-left: 0.5rem;
      font-weight: normal;
    }
  }

  &__actions {
    justify-content: space-between;

    @include breakpoint(tablet) {
      flex-direction: column;
      gap: 1rem;
      align-items: flex-start;
    }
  }
}
