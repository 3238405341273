@import '../../_styles/text';

:root {
  --swiper-theme-color: var(--c-p);
}

.swiper-carousel {
  position: relative;

  .swiper {
    position: initial;
  }

  .swiper-slide {
    height: auto;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10rem;
    margin-bottom: 2rem;

    span {
      @include text(lead);
      color: var(--grey);
    }
  }

  .ecl-carousel__controls {
    padding: 0;

    .ecl-carousel__prev,
    .ecl-carousel__next {
      &.ecl-carousel__prev--minimal,
      &.ecl-carousel__next--minimal {
        opacity: 1;
        background-color: transparent;
        color: var(--blue);
      }
    }

    @media screen and (min-width: 1280px) {
      .ecl-carousel__prev,
      .ecl-carousel__next {
        opacity: 0.75;
      }
      .ecl-carousel__prev {
        left: -5rem;

        &--minimal {
          left: -3rem;
        }
      }
      .ecl-carousel__next {
        right: -5rem;

        &--minimal {
          right: -3rem;
        }
      }
    }
  }
}

.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: -2rem;
}
