@import '../../_styles/_buttons.scss';

.socials__copy {
  @include unstyled-button;
  border: none;
  .ecl-link__label {
    color: var(--c-p);
    &:hover {
      text-decoration: underline;
    }
  }
}
.ecl-social-media-share__list {
  margin-top:0;
}

// @import '../../_styles/buttons';

// .socials__twitter,
// .socials__facebook,
// .socials__copy,
// .socials__linkedin {
//   @include unstyled-button;
//   display: flex;
//   align-items: center;
//   color: var(--white);
//   padding-right: 1rem;
//   height: 3rem;

//   &:hover {
//     color: var(--white);
//   }

//   .icon {
//     height: 100%;
//     margin-right: 0.5rem;
//     padding: 0 0.5rem;
//   }
// }

// .socials__twitter {
//   background: var(--twitter);

//   .icon {
//     background-color: var(--twitter-icon);
//   }
// }

// .socials__facebook {
//   background-color: var(--facebook);

//   .icon {
//     background-color: var(--facebook-icon);
//   }
// }

// .socials__linkedin {
//   background-color: var(--linkedin);

//   .icon {
//     background-color: var(--linkedin-icon);
//   }
// }

// .socials__copy {
//   background-color: var(--linkedin);

//   .icon {
//     background-color: var(--copy-icon);
//   }
// }
