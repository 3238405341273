@import '../../../_styles/text';
@import '../../../_styles/breakpoints';
@import '../../../_styles/spacing';

.extra-page {
  .title {
    margin-top: 2rem;
  }

  &__about {
    @include padding;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    @include breakpoint(tablet) {
      flex-direction: column;
    }

    .picture {
      min-width: 50%;
      max-width: 50%;

      @include breakpoint(tablet) {
        min-width: 100%;
      }
    }

    h3 {
      margin-bottom: 1rem;
    }
  }

  &__gallery {
    @include padding;
    background-color: var(--blue-grey);

    h3 {
      margin-bottom: 2rem;
    }
  }

  &__section {
    padding: 4rem 0;

    h3 {
      margin-bottom: 1rem;
    }
  }
}
