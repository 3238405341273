@import '../../_styles/buttons';

.show-more {
  @include unstyled-button;
  display: flex;
  align-items: center;
  color: var(--blue);
  font-weight: bold;
  font-size: 0.875rem;

  .ecl-icon {
    margin-left: 0.5rem;
  }
}
