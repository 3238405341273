@import '../../../_styles/spacing';
@import '../../../_styles/breakpoints';

.community-platform {
  @include padding;

  &__blocks {
    margin-top: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;

    @include breakpoint(monitor) {
      grid-template-columns: 1fr 1fr;
    }

    @include breakpoint(tablet) {
      grid-template-columns: 1fr;
    }

    img {
      width: 100%;
    }

    h3 {
      font-weight: normal;
      margin: 0.25rem 0;
    }

    p {
      color: var(--grey-dark);
    }
  }
}
