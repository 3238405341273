.markdown {
  overflow: auto;

  p:not(:last-of-type) {
    margin-bottom: 1rem;
  }

  a {
    color: var(--primary);
    font-weight: bold;
  }

  ol {
    counter-reset: item;
  }
  ol > li,
  ul > li {
    counter-increment: item;
    margin-bottom: 5px;
    display: flex;
    gap: 0.5rem;
    padding-left: 1rem;
  }

  ol > li:before {
    content: counter(item) '.';
  }
  ul > li:before {
    content: '•';
    margin: 0 0.25rem;
  }
}
